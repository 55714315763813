import { BaseColors } from './themeTypes';

export const baseColors: BaseColors = {
  '--colors-accentBrand': '#fff500',
  '--colors-alabaster': '#fafafa',
  '--colors-attention': '#A27601',
  '--colors-barleyWhite': '#FFF2D1',
  '--colors-black': '#000',
  '--colors-borderedButtonBackground': 'transparent',
  '--colors-brandBlock': '#e30620',
  '--colors-brandLightGrey1': '#efefef',
  '--colors-brandLightGrey10': '#f2f2f2',
  '--colors-brandLightGrey2': '#dddddd',
  '--colors-brandLightGrey3': '#d8d8d8',
  '--colors-brandLightGrey5': '#f8f8f8',
  '--colors-brandLightGrey6': '#DADADA',
  '--colors-brandLightGrey7': '#D2D2D2',
  '--colors-brandLightGrey8': '#9f9f9f',
  '--colors-brandLightGrey9': '#FBFBFB',
  '--colors-capeCod': '#404844',
  '--colors-darkBrand': '#bf051b',
  '--colors-darkGreen': '#00904a',
  '--colors-darkGrey': '#b4b4b4',
  '--colors-darkRed': '#e64242',
  '--colors-darkenBrand1': '#bf051b',
  '--colors-darkenBrand2': '#bf051b',
  '--colors-darkenGrey1': '#9b9b9b',
  '--colors-darkenGrey2': '#232926',
  '--colors-darkenGrey3': '#616d65',
  '--colors-darkenGrey4': '#979797',
  '--colors-darkenGrey5': '#565860',
  '--colors-darkenGrey6': '#9b9b9b',
  '--colors-doveGray': '#6A6A6A',
  '--colors-fbIcon': '#3C5996',
  '--colors-frostedMint': '#f4fbf7',
  '--colors-goldenGrass': '#DA9722',
  '--colors-green': '#45b759',
  '--colors-iconBrandColor': '#013E7F',
  '--colors-invalidBackground': '#FFF8EB',
  '--colors-invalidBorder': '#d79f9f',
  '--colors-lightGreen': '#E9F8EF',
  '--colors-lightGrey1': '#F6F6F6',
  '--colors-lightGrey2': '#E8ECE9',
  '--colors-lightGrey3': '#E8ECF2',
  '--colors-lightRed': '#fff2f2',
  '--colors-monza': '#D70006',
  '--colors-nightRider': '#333333',
  '--colors-orange': '#ffcf1b',
  '--colors-paleRed': '#fff1f1',
  '--colors-pomegranate': '#F1471D',
  '--colors-red': '#ff5252',
  '--colors-ripeLemon': '#F5DA1C',
  '--colors-seaGreen': '#349E44',
  '--colors-secondaryBrandColor': '#497527',
  '--colors-sensitivePlatinum': '#ECECEC',
  '--colors-silver': '#C4C4C4',
  '--colors-supernova': '#FFCB05',
  '--colors-text': '#303030',
  '--colors-transparent': 'transparent',
  '--colors-twitterIcon': '#41C9FD',
  '--colors-white': '#fff',
  '--colors-zakazGreen': '#1EB763',
  '--colors-heavyMetal': '#222421',
};

export default baseColors;
